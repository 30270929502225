@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: black;
    overflow-x: hidden;
}

/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 5px 20px;
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.logo img {
    width: 100px;
    height: auto;
}

/* Nav Links */
.navLinks {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    transition: transform 0.3s ease;
    transform: translateX(0);
    margin-left: auto;
}

.navLinks.open {
    transform: translateX(0);
}

.navLinks li {
    margin: 0 10px;
}

.navLinks li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #333;
    transition: background-color 0.3s, border-color 0.3s;
    display: flex;
    align-items: center;
}

.navIcons {
    color: white;
    font-size: 1.2rem;
}




.navLinks li a:hover {
    background-color: gold;
    border-color: gold;
    color: black;
}

.navLinks li a:hover .navIcons {
    color: black;
    /* Ensure the icon changes color on hover */
}



.navLinks li a i {
    margin-right: 5px;
}

/* Hamburger Menu */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
}

/* Responsive */
@media (max-width: 768px) {
    .navbar {
        background-color: black;
        padding: 10px 30px;
        align-items: center;
        justify-content: space-between;
    }

    .navLinks {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 50px;
        left: 0;
        background-color: #252525;
        width: 100%;
        padding: 10px 0;
        z-index: 10;
        margin-top: 4rem;
    }

    .navLinks.open {
        display: flex;
    }

    .navLinks li {
        margin: 5px 0;
    }

    .navLinks li a {
        font-size: 16px;
        padding: 10px;
        transition: background-color 0.3s ease;
    }

    .navLinks li a:hover {
        background-color: #333;
        color: gold;
    }

    .hamburger {
        display: flex;
        z-index: 20;
    }

    .hamburger div {
        background-color: white;

    }
}